<template>
	<article class="row justify-center p-3">
		<section class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx">
			<h2 class="h2 ">Botox</h2>
			<p class="lead first">
				Gel voor het haar is een revolutionaire
				methode om het haar zacht, pluisvrij en gezond te krijgen met
				langdurig resultaat (2-5 maanden). <br> De gel versterkt je haar met
				eiwitten die het nodig heeft om gezond en sterk te blijven. Een
				Softliss BOTOX behandeling geeft verbluffend resultaat.
			</p>
		</section>
		<section class="col-12-xs col-12-sm col-12-mm col-6-md col-6-lg col-6-xl col-6-xx">
			<img src="@/assets/img/Face9.jpg" class="img" alt="" />
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Botox',
	}
</script>

<style scoped lang="scss">
</style>
